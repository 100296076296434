import React from 'react'

import { Link } from 'gatsby'

import AppStoreBadge from '../../svg/appstore.inline.svg'

import './index.css'

const Right = () => (
  <div className="right-container">
    <div>
      <h2>WANT EARLY ACCESS?</h2>
      <Link to="/requestinvite">
        <div className="button">Request an Invite</div>
      </Link>
      <div className="spacer" />
      <a
        href="https://apps.apple.com/us/app/relatable-connecting-parents/id1558025864"
        target="_blank"
        rel="noreferrer"
      >
        <AppStoreBadge className="appstore-badge" />
      </a>
    </div>
  </div>
)

export default Right
