import React from "react"

import { Link } from "gatsby"

import "./index.css"

const Left = () => (
  <div className="left-container">
    <h1>Welcome to Relatable!</h1>
    <p>
      Hello! We&apos;re excited to introduce Relatable, the new social app
      designed for truly authentic conversation.
    </p>
    <p>
      It&apos;s time to break away from ad-driven social platforms overrun by
      trolls and spam. Relatable is completely ad-free and we&apos;ll never
      track or sell your info or content.
    </p>
    <p>
      We&apos;ve created the first online social platform where you can enjoy
      forming genuine connections while being in total control of the content
      you see. That&apos;s right - we don&apos;t manipulate your experience for
      our own benefit.
    </p>
    <p>
      Relatable is centered around community rather than likes and follows. If
      you&apos;ve built a following elsewhere, invite them to Relatable and
      connect on a deeper level.
    </p>
    <p>
      Relatable is currently invite only. You can{" "}
      <Link to="/requestinvite">request an invite here</Link>.
    </p>
  </div>
)

export default Left
