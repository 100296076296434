import React from 'react'

import Layout from '../components/layout/layout'
import SEO from '../components/seo'

import Left from '../components/index/left'
import Right from '../components/index/right'

import '../components/index/index.css'

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div className="container">
      <Left />
      <Right />
    </div>
  </Layout>
)

export default IndexPage
